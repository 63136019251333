// src/pages/PaymentPage.js

import React, { useState, useEffect,useRef } from 'react';
import logo from '../utils/logo.webp'; 
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { bancolombiaConfig } from '../config';
import moment from 'moment-timezone';
/*
let bancolombiaConfig;
if (process.env.NODE_ENV === 'development') {
  bancolombiaConfig = require('../config');
}*/

/*const bancolombiaConfig = {
  data: JSON.parse(process.env.REACT_APP_BANCOLOMBIA_DATA || '{}'),
  headers: JSON.parse(process.env.REACT_APP_BANCOLOMBIA_HEADERS || '{}')
};*/

const getUpdatedBancolombiaConfig = (total, email) => {
  return {
    ...bancolombiaConfig,
    data: {
      ...bancolombiaConfig.data,
      data: {
        ...bancolombiaConfig.data.data,
        transactions: {
          ...bancolombiaConfig.data.data.transactions,
          amount: total,  // Aquí agregamos el total
          transactionReference: email 
        }
      }
    }
  };
};

function PaymentPage({ 
  total,
  setTotal,
  setUploadedFiles,
  formData,
  uploadedFilesInfo,
  setUploadedFilesInfo,
  deleteRow,
  updateTotal
}) {
  // Ejecuta handleLoadQR al cargar la página
  const [qrImage, setQrImage] = useState(null);
  const navigate = useNavigate();
  const qrRef = useRef(null);
  const [pngUrl, setPngUrl] = useState(null);
  const [qrError, setQrError] = useState(null);
  console.log(uploadedFilesInfo)
 
  const handleLocalPayment = async () => {

    try {
      // Realizar la solicitud GET con axios
      await axios.get('https://databasemegapapel-production.up.railway.app/api/update-state-docs', {
        params: {
          state: "Pago local",  // Pasar idDocs como parámetro
          employee: "Sin asignar",
          email: formData.email
        }
      });
    
      // Si la solicitud es exitosa, limpiar los datos almacenados y redirigir
      localStorage.removeItem('uploadedFiles');
      setUploadedFiles([]); 
      localStorage.removeItem('uploadedFilesInfo');
      setUploadedFilesInfo([]); 
    
      // Navegar a la página '/local-payment-info' después de limpiar los datos
      navigate('/local-payment-info');
    
    } catch (error) {
      // Si ocurre un error, mostrar en consola y redirigir a la página de error
      console.error('Error en la solicitud de pago local:', error);
      localStorage.removeItem('uploadedFiles');
      setUploadedFiles([]); 
      localStorage.removeItem('uploadedFilesInfo');
      setUploadedFilesInfo([]); 
      navigate('/error');  // Redirigir a la página de error
    }
  
  };
  const handleNavigateHome = () => {
    localStorage.removeItem('uploadedFiles');
    setUploadedFiles([]); 
    localStorage.removeItem('uploadedFilesInfo');
    setUploadedFilesInfo([]); 
    navigate('/'); // Navega a la página de inicio
  };


useEffect(() => {
  const fetchQRCode = async () => {
    const updatedBancolombiaConfig = getUpdatedBancolombiaConfig(total, formData.email);

      if (!total || !formData.email) return; // Verificar si tenemos total y email antes de hacer la solicitud

      try {
          const responseQR = await axios.post('https://gw-medios-de-pago.apps.bancolombia.com/public-partner/production/v2/sales-service/cross-channel/transaction-authorization/qr-codes', updatedBancolombiaConfig.data, {
              headers: updatedBancolombiaConfig.headers,
          });
          const txtQR = responseQR.data.data.qrImage;
          const base64Img = txtQR;
          const utf8Encoded = base64Img;
          setQrImage(utf8Encoded);
          setQrError(null);
      } catch (error) {
          console.error('Error al obtener el QR:', error);
          setQrError('No se pudo hacer la solicitud del QR con Bancolombia. Vuelva a iniciar el proceso o pague en local.');
      }
  };

  //fetchQRCode();  // Ejecutar la solicitud de QR solo una vez cuando se cumplan las condiciones
  const APIBancolombia = async (amountBC, payerReferenceBC) => {    
    // Obtener la hora actual en Colombia
  const currentDateInColombia = moment.tz("America/Bogota").startOf('minute');  // Redondear a los minutos

    
    try {
      const response = await axios.get('https://megapapelapp.com/pay');
    
  
      if (response.data.success && response.data.token) {
        const token = response.data.token;
        console.log("Token recibido:", token);
        // Convertir la hora de la transferencia a un objeto moment en Colombia
        const transferDate = moment.tz(token.data.transferDate, "America/Bogota").startOf('minute');  // Redondear a los minutos
        const transferAmount = token.data.transferAmount;
        const payerReference = token.data.clientInformation.payerReference;
  
        if (token.data.transferState === 'approved' && currentDateInColombia.isSameOrBefore(transferDate) &&transferAmount===amountBC && payerReference===payerReferenceBC ){
          // Ejecutar la solicitud adicional
          await axios.get('https://databasemegapapel-production.up.railway.app/api/update-state-docs', {
            params: {
              state: "Transferencia",
              employee: "Sin asignar",
              email: formData.email,
            },
          });
          localStorage.removeItem('uploadedFiles');
          setUploadedFiles([]);
          localStorage.removeItem('uploadedFilesInfo');
          setUploadedFilesInfo([]);
          // Redirigir a otra página
          navigate('/qr-payment-info');
        } else {
          console.log('Transferencia no aprobada:', response.data.message);
        }
      } else {
        console.log('No se ha recibido un token válido:', response.data.message);
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
      localStorage.removeItem('uploadedFiles');
      setUploadedFiles([]); 
      localStorage.removeItem('uploadedFilesInfo');
      setUploadedFilesInfo([]); 
      navigate('/error');
    }
  };
  const validatePayment = () => {
    const updatedBancolombiaConfig = getUpdatedBancolombiaConfig(total, formData.email);
    const amount = updatedBancolombiaConfig.data.data.transactions.amount;
    const payerReference = updatedBancolombiaConfig.data.data.transactions.transactionReference;

    if (total && formData.email) {
      APIBancolombia(amount, payerReference);
    }
  };
  fetchQRCode(); // Ejecutar la solicitud de QR solo una vez cuando se cumplan las condiciones

  const interval = setInterval(validatePayment, 10000);

  const handleVisibilityChange = () => {
    if (!document.hidden) {
      validatePayment();
    }
  };

  document.addEventListener('visibilitychange', handleVisibilityChange);


  const timeout = setTimeout(async () => {
      try {
          clearInterval(interval);
          localStorage.removeItem('uploadedFiles');
          setUploadedFiles([]);
          localStorage.removeItem('uploadedFilesInfo');
          setUploadedFilesInfo([]);
          await axios.get('https://databasemegapapel-production.up.railway.app/api/update-state-docs', {
              params: {
                  state: "Sin procedimiento elegido",
                  employee: "Sin asignar",
                  email: formData.email
              }
          });
          navigate('/');
      } catch (error) {
          console.error('Error en la solicitud de conexión base de datos:', error);
          localStorage.removeItem('uploadedFiles');
          setUploadedFiles([]); 
          localStorage.removeItem('uploadedFilesInfo');
          setUploadedFilesInfo([]); 
          navigate('/error');
      }
  }, 300000);  // Timeout de 5 minutos

  return () => {
      clearInterval(interval); // Limpiar el intervalo al desmontar el componente
      clearTimeout(timeout);   // Limpiar el timeout al desmontar el componente
      document.removeEventListener('visibilitychange', handleVisibilityChange);
  };
}, [total, formData.email, navigate,setUploadedFiles,setUploadedFilesInfo]);


  const handleDownloadQR = () => {
    if (!pngUrl) return; // Asegurarse de que existe el PNG URL
  
    // Crear un enlace temporal para la descarga
    const link = document.createElement('a');
    link.href = pngUrl;
    link.download = 'qr-code.png'; // Nombre del archivo descargado
  
    // Simula un clic para iniciar la descarga
    link.click();
  
    // Limpieza: eliminar el enlace temporal
    link.remove();
  };

  
  useEffect(() => {
    if (qrImage) {
      // Crear una imagen desde el base64 SVG
      const svgImage = new Image();
      svgImage.src = `data:image/svg+xml;base64,${qrImage}`;
      
      svgImage.onload = () => {
        // Definir el factor de escala (ej. 3x para mejorar la calidad)
        const scale = 3;

        // Obtener las dimensiones reales de la imagen SVG
        const width = svgImage.naturalWidth;
        const height = svgImage.naturalHeight;

        // Crear un canvas con tamaño ajustado
        const canvas = document.createElement('canvas');
        canvas.width = width * scale;
        canvas.height = height * scale;

        const ctx = canvas.getContext('2d');

        // Aumentar la resolución al escalar el contenido
        ctx.scale(scale, scale);
        ctx.drawImage(svgImage, 0, 0, width, height);

        // Convertir canvas a PNG en base64
        const pngBase64 = canvas.toDataURL('image/png');
        setPngUrl(pngBase64); // Guardar la URL PNG en el estado
      };
    }
  }, [qrImage]);
  // Función para calcular el total basado en los archivos
  const calculateTotal = (filesInfo) => {
    return filesInfo.reduce((acc, file) => acc + file.totalP, 0);
  };

  // Calcular el total cada vez que cambie uploadedFilesInfo
  useEffect(() => {
    setTotal(calculateTotal(uploadedFilesInfo));
  }, [uploadedFilesInfo,setTotal]);

   // Función para eliminar un archivo específico
  const handleRemoveFile = async (index) => {

    const email = uploadedFilesInfo[index]?.email;  // Asegúrate de que `idDocs` exista en tus datos
    const idDocs = uploadedFilesInfo[index]?.idDocs;  // Asegúrate de que `idDocs` exista en tus datos
    // Eliminar el archivo en el índice especificado de la lista local
    const newUploadedFilesInfo = uploadedFilesInfo.filter((_, i) => i !== index);
    setUploadedFilesInfo(newUploadedFilesInfo);
    setTotal(calculateTotal(newUploadedFilesInfo));
    deleteRow(email,index,idDocs)
    updateTotal(calculateTotal(newUploadedFilesInfo),email)

  };
 

  return (
    <div className="payment-page">
      <button className='button'  onClick={handleNavigateHome}>Inicio</button>
      <div className="payment-page-info">
        
        <img className="logo" src={logo} alt="Logo"  />
        {uploadedFilesInfo.length > 0 && (
        <div className='resumen'>
          <h4>Archivos subidos:</h4>
          <div className='resumen-grid'>
            {uploadedFilesInfo.map((fileInfo,index) => (
              <div className='resumenInfo' key={fileInfo.id || index}>
                <div className="file-info-row">
                  <p><strong className="darker-text">Nombre</strong>: {fileInfo.name}</p>
                  <p><strong className="darker-text">Opción de impresión</strong>: {fileInfo.color}</p>
                  <p><strong className="darker-text">Material</strong>: {fileInfo.material}</p>
                  {fileInfo.material === "Fotográfico" && (
                    <p><strong className="darker-text"># Copias de imagenes</strong>: {fileInfo.copiesImage}</p>
                  )}
                  <p><strong className="darker-text">Tamaño</strong>: {fileInfo.size}</p>
                  <p><strong className="darker-text"># Copias</strong>: {fileInfo.copies}</p>
                  <p><strong className="darker-text">Doble cara</strong>: {fileInfo.sides}</p>
                  <p><strong className="darker-text">Argollado</strong>: {fileInfo.hooped}</p>
                  <p><strong className="darker-text">#Páginas del documento</strong>: {fileInfo.numPagesF}</p>
                  <p><strong className="darker-text">#Páginas a imprimir</strong>: {fileInfo.specificPages}</p>
                  <p><strong className="darker-text">Comentarios</strong>: {fileInfo.comments}</p>
                  <p><strong className="darker-text">Total</strong>: {fileInfo.totalP}</p> 
                  {uploadedFilesInfo.length > 1 && (
                  <button className="delete-button" onClick={() => handleRemoveFile(index)}>Eliminar archivo</button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

        <h2>Total a pagar: ${total}</h2>
        {qrImage ? (
          <div className="qr" ref={qrRef}>
            <img src={`data:image/svg+xml;base64,${qrImage}`} alt="QR Code" />
            <button className='download-btn' onClick={handleDownloadQR}>
              Descargar QR
            </button>
          </div>
        ) : qrError ? (
          <p style={{ color: 'red' }}>{qrError}</p>
        ) : (
          <h1>Cargando QR...</h1>
        )}
        <p>Si deseas que imprimamos tu pedido de inmediato, puedes realizar el pago a través de un código
           QR de Bancolombia. <br></br> Alternativamente, puedes presionar el botón "Pagar en local" para pagar en efectivo,
           y procederemos con la impresión una vez hayas realizado el pago en nuestra tienda.
        </p>
        <button className='button' type="button" onClick={handleLocalPayment}>Pagar en local</button>
      </div>
     
    </div>
  );
}

export default PaymentPage;